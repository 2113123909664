import React, { useState, useEffect } from 'react'
import Router, { useRouter, withRouter } from 'next/router';
import { usePagination, useFilters, useRowSelect, useSortBy, useAbsoluteLayout, SelectColumnFilter, useTable } from 'react-table'
import _, { uniqueId } from "lodash";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <input
      type="text"
      className="form-control"
      value={filterValue || ''}
      onChange={e => {
        { setFilter(e.target.value || undefined) }
      }}
      placeholder={`Search Here`}
    />
  )
}




export default function Table({ columns, data }) {

  const router = useRouter();
  const queryParam = [];
  const [loading, setLoading] = useState(false);
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,


    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    page,
    state,
    getToggleHideAllColumnsProps
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: 'created_at',
            desc: true
          }
        ],
        hiddenColumns: columns.filter(column => column?.isHidden).map(column => column.id)
      },
      manualPagination: true,
      //pageCount: meta?.last_page || 1,
      manualFilters: true,
      manualSortBy: true,
      disableSortRemove: true,
      useAbsoluteLayout
    },
    useFilters, useSortBy, usePagination, useRowSelect,
  )
  React.useEffect(() => {
    state.filters.map((o) => {
      if (o.value.length >= 3) {
        queryParam[o.id] = o.value;
      }
    })
    if (state.sortBy[0]) {
      queryParam.order_by = state.sortBy[0]?.id;
      queryParam.order_in = (state.sortBy[0]?.desc) ? "desc" : "asc";
    }
    router.push(router.asPath,{     
            query: queryParam,
    });
  }, [state.filters, state.sortBy]);


  return (
    <>
      <table className='table table-sm table-striped table-hover' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={`heading_tr_${index}`} {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column, colIndex) => (
                <th key={`heading_th_${colIndex}`} className="text-nowrap text-center" {...column.getHeaderProps(column.getSortByToggleProps({ style: column?.style, }))} >
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ▼'
                        : ' ▲'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={`search_tr_${index}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, colIndex) => (
                <>
                  <th>{column.canFilter ? column.render('Filter') : null}</th>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody  {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr key={`tbody_tr_${index}`} >
                {row.cells.map((cell, cellIndex) => {
                  return <td key={`tbody_td_${cellIndex}`} {...cell.getCellProps({
                    className: cell.column?.className
                      ?? "text-nowrap",
                    style: cell.column?.style,
                  })}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

// {column.canFilter ? column.render('Filter') : null}
