import React, { useContext, useState, useTransition } from "react";
import widgetsModel from "../models/widgets.model";
import useSWR from 'swr'
import Heading from "../components/Heading";
import ordersModel from "../models/orders.model";
import config from "../lib/config";
import Table from "../components/Table";
import helper from "../lib/helper";
const Page = function () {

    const { data: orders } = useSWR('OrderWidget', () => widgetsModel.orders())
    const { data: products } = useSWR('ProducctWidget', () => widgetsModel.products())
    const { data: ordersData, mutate: reloadordersData, isLoading } = useSWR(
        "order-widgets",
        () => ordersModel.list({ "order_by": 'created_at', 'order_in': "desc" }),
        config.swrConfig
    );

    const columns = [
        {
            Header: "Order Id",
            accessor: "sub_order_id",
            className: "col",
            Filter: false,
        },
        {
            Header: "Name",
            Cell: ({ row }) => (
                <>
                    {row.original.order?.name}<br />
                    {row.original.order?.mobile}
                </>
            ),
            className: "col",
            Filter: false,
        },
        {
            Header: "Items",
            className: "col",
            Cell: ({ row }) => (
                <>
                    {row.original.title}<br />
                    {row.original.sku}
                </>
            ),
            Filter: false,
        },
        {
            Header: "Amount",
            accessor: "selling_price",
            className: "col",
            Filter: false,
        },

        {
            Header: "Payment mode",
            accessor: "payment_mode",
            className: "col",
            Cell: ({ row }) => (
                <>
                    {row.original.order?.payment_mode}
                </>
            ),

            Filter: false,
        },

        {
            Header: "Payment Status",
            Cell: ({ row }) => (
                <>
                    {row.original.order?.payment_status}
                </>
            ),
            className: "col",
            Filter: false,
        },
        {
            Header: "Status",
            Cell: ({ row }) => (
                <>
                    {row.original.order?.status}
                </>
            ),
            className: "col",
            Filter: false,
        },
        {
            Header: "Created At",
            Cell: ({ row }) => (
                <>
                    {helper.date.format(row.original?.created_at)}</>
            ),
            className: "col",
            Filter: false,
        },

    ];
    return (
        <>
            <Heading title="Dashboard" />
            <div className="container-fluid">
                <div className="card mb-2 border-0">
                    <div className="card-header">
                        Orders
                    </div>
                    <div class="row g-1 row-cols-2 row-cols-lg-4">
                        <div class="col">
                            <div class="card bg-secondary bg-gradient text-light">
                                <div class="card-body">
                                    <h6 class="mb-3">Total Orders</h6>
                                    <h2 class="text-right"><i class="bi bi-box"></i><span className="float-end">{orders?.data?.total ?? 0}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card bg-success bg-gradient text-light">
                                <div class="card-body">
                                    <h6 class="mb-3">Total Booked</h6>
                                    <h2 class="text-right"><i class="bi bi-box"></i><span className="float-end">{orders?.data?.total ?? 0}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card bg-warning bg-gradient text-light">
                                <div class="card-body">
                                    <h6 class="mb-3">Total packed</h6>
                                    <h2 class="text-right"><i class="bi bi-box"></i><span className="float-end">{orders?.data?.packed ?? 0}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card bg-danger bg-gradient text-light">
                                <div class="card-body">
                                    <h6 class="mb-3">Total Cancelled</h6>
                                    <h2 class="text-right"><i class="bi bi-box"></i><span className="float-end">{orders?.data?.cancel ?? 0}</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="card mb-2 border-0">
                    <div className="card-header">
                        Products
                    </div>
                    <div class="row g-1 row-cols-2 row-cols-lg-4">
                        <div class="col">
                            <div class="card bg-secondary bg-gradient text-light">
                                <div class="card-body">
                                    <h6 class="mb-3">Total Products</h6>
                                    <h2 class="text-right"><i class="bi bi-boxes"></i><span className="float-end">{products?.data?.total}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card bg-success bg-gradient text-light">
                                <div class="card-body">
                                    <h6 class="mb-3">Published Products</h6>
                                    <h2 class="text-right"><i class="bi bi-boxes"></i><span className="float-end">{products?.data?.publish}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card bg-warning bg-gradient text-light">
                                <div class="card-body">
                                    <h6 class="mb-3">Pending Products</h6>
                                    <h2 class="text-right"><i class="bi bi-boxes"></i><span className="float-end">{products?.data?.pending}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card bg-danger bg-gradient text-light">
                                <div class="card-body">
                                    <h6 class="mb-3">Rejected Products</h6>
                                    <h2 class="text-right"><i class="bi bi-boxes"></i><span className="float-end">{products?.data?.rejected}</span></h2>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="card">
                    <div className="card-header">Orders List </div>

                    <div className="table-responsive">
                        <Table columns={columns} data={ordersData?.data || []} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Page;
